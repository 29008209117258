.wrapper{
    height:100%;
}
.header{
    min-height:50vh;
    background-image:url('/public/pages/living-room.jpg');
    background-position:0% 65%;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    gap:100px;
}
.headertop{
    font-weight: 700;
    background-color:#FFFFFFBB;
    border-radius:40px;
    width:40%;
    text-align:center;
    padding:40px;
    font-size:calc( 3 * 1.2vw + 1rem);
    font-family: TitleFont;
    margin-top:40px;
}
.headerDetail{
    display:flex;
    justify-content: space-around;
    width:100%;
    margin-bottom:40px;
}
.headerDetail>div{
    background-color:#FFFFFFBB;
    text-align:center;
    border-radius:40px;
    text-align:center;
    width:20%;
    padding:20px;
    font-size:x-large;
}
.description{
    font-size:x-large;
    font-family:TitleFont;
    color:var(--general-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    padding:50px;
    background-color:#eaeaee;
}
.description div{
    text-align:center;
    width:50%;
    font-size:calc(1.1* 1.2vw + 1rem)
}
.description .orange{
    color:var(--general-orange);
}
.windowWrapper{
    background-color:#eaeaee;
    width:100%;
    display:flex;
    justify-content: space-between;
}
.windowWrapper>div{
    flex:1;
    display:flex;
    flex-direction:column;
}
.sideImage{
    width:80%;
    align-self: center;
    margin :25px;
    border-radius:10px;
}
.window{
    width:100%;
    max-width:1400px;
    padding:50px 25px 100px 25px;
    text-align:left;
    color:var(--general-blue);
}
.window h3{
    text-align:left;
    color:var(--general-blue);
    font-size:calc(1.5 * 1.2vw + 1rem);
    font-weight:lighter;
}
.window p{
    font-size:calc((0.1) * 1.2vw + 1rem)
}

.homeWrapper{
    width:100%;
    text-align:left;
    background-color:var(--general-blue);
    padding-top:50px;
    padding-bottom:150px;
    display:flex;
    justify-content: space-between;
}
.homeWrapper>div{
    flex:1;
    display:flex;
    flex-direction:column;
}
.home{
    text-align:left;
    color:white;
    padding-right:50px;
}
.home h3, .home p{
    text-align:left;
    color:white;
}
.home p{
    font-size:calc((0.1) * 1.2vw + 1rem)
}
.home h3{
    text-align:left;
    font-size:calc(1.5 * 1.2vw + 1rem);
    font-weight:lighter;
}
.quadriclesWrapper{
    background-color:#eaeaee;
    display:flex;
    justify-content: center;
}
.quadricles{
    display:flex;
    flex-wrap:wrap;
    justify-content: space-around;
    padding-bottom:50px;
    max-width:1400px;
}
.quadricle{
    width:20%;
    text-align:center;
}
.quadricle h6{
    font-size:1.2rem;
    font-weight:lighter;
    font-family:TitleFont;
    margin:10px;
}
.quadricle img{
    width:200px;
    height:200px;
    border-radius:100px;
}
.homeQuad{
    background-color:var(--general-blue);
    color:white !important;
}

.bundle{
    background-color:#eaeaee;
    height:80vh;
}
.bundleWave{
    position:absolute;
    z-index:0;
}
.bundleContent{
    background-color:#eaeaee;
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    gap:100px;
}
.bundleContent img{
    z-index:5;
    height:85%;
    border-radius:10px;
}
.bundleText{
    width:30%;
    color:var(--general-blue);
    text-align:center;
}
.bundleText p{
    font-size:calc(0.1 * 1.2vw + 1rem);
    font-family:TitleFont;
    margin-bottom:50px;
}

@media (max-width: 768px) {
    .header{
        height:85vh;
        text-align:center;
        background-position:40% 45%;
    }
    .headertop{
        width:70%;
        padding:30px;
    }
    .headerDetail>div{
        font-size:calc(0.4 * calc(.012 * min(100vh, 900px)) + 1rem) !important;
        font-weight:bold;
    }
    .headerDetail, .bundleContent{
        gap:20px;
    }
    .description{
        padding:20px;
        padding-top:50px;
    }
    .description div{
        width:100%;
    }
    .window p{
        width:100%;
    }
    .windowWrapper{
    flex-direction:column-reverse;
    }
    .quadricle, .window, .home p{
        width:auto;
    }
    .homeWrapper{
        padding-top:10px;
        flex-direction:column;
        align-items: center;
        padding-bottom:50px;
    }
    .home{
        display:block;
        width:90%;
        padding:0px;
        padding-top:50px;
    }
    .home h3{
        text-align:left;
        width:auto;
    }
    .home p{
        width:100%;
    }
    .bundleContent{
        flex-direction: column;
    }
    .bundleContent img{
        width:80%;
        height:auto;
    }
    .bundleText{
        width:90%;
    }
    .bundleContent{
        height:100vh;
        width:100%;
    }
}