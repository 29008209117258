.wrapper{
  height:30vh;
  width:100%;
  background-color:var(--general-blue);
  display:flex;
  justify-content: center;
  align-items: center;
}
.footer{
  height:60%;
    text-align:center;
    font-weight:bold;
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    color:white;
    max-width:1400px;
    width:100%;
    padding:50px;
}
.footer h2{
  font-size:2.5rem;
  font-family:TitleFont;
  font-weight:lighter;
  margin:0;
  text-align:left;
}
.info{
  display:flex;
  gap:100px;
}
.infoBlock div{
  text-align:left;
  font-family:TitleFont;
  font-weight:lighter;
}
.infoBlock>div:first-child{
  font-size:20px;
  margin-bottom:20px;
}

.bottomNav{
  width:50%;
  display:flex;
  justify-content: space-around;
  align-items: center;
}
.bottomNav a{
  color:white;
}
.link{
  color:white !important;
}
.contactEmail {
  color: var(--general-orange);
}

.contactPhone {
  font-size: 16px;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .wrapper{
    height:70vh;
  }
  .footer{
    height:auto;
    flex-direction:column;
    justify-content: space-around;
    height:100%;
    padding:0 25px 0 25px;
  }
  .info{
    flex-direction:column;
  }
  .footer h2{
    font-size:2rem;
  }
}