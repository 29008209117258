/* Services.css */

.services-container {
    text-align: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #eaeaee;
    color: #444;
    min-height:90vh;
}

.services-heading {
    font-size: 4rem;
    font-family:TitleFont;
    font-weight:lighter;
    color: var(--general-blue);
    margin-bottom: 20px;
}

.services-content {
    display: flex;
    justify-content: space-around;
    max-width:1400px;
    flex-wrap: wrap;
    margin: 0 auto;
}

.service-item {
    width: 30%;
    padding: 15px;
    text-align: left;
}

.service-image {
    width: 100%;
}

.service-title {
    font-size: 1.5rem;
    font-weight:lighter;
    font-family:TitleFont;
    color: #333;
    margin-top: 40px;
    text-align:left;
}

.service-description {
    font-size: 1.1rem;
    color: #000;
    margin-top: 10px;
    line-height: 1.5;
}

.quoteButton{
    margin-top:40px;
    margin-bottom:40px;
}

@media (max-width: 768px) {
    .services-container{
        height:auto;
        padding-bottom:50px;
    }
    .services-heading{
        font-size:calc(3* calc(.012* min(100vh, 900px)) + 1rem);
    }
    .services-content {
        flex-direction: column;
        align-items: center;
    }

    .service-item {
        width: 90%;
    }
}
