.wrapper{
    display:flex;
    flex-direction:column;
    gap:25px;
    padding:0px 25px 0px 25px;
}
.wrapper p{
    text-align:center;
}

.head{
    width:90%;
    display:flex;
    flex-direction:column;
    align-items: center;
    background-color:#f0f0f5;
    gap:10px;
    align-self:center;
    border:1px solid black;
    border-radius:5px;
    padding:10px;
}

.head h1{
    font-size:20px;
    margin:0px;
}

.head img{
    width:100px;
}

.dropdownGroup{
    width:90%;
    padding:10px;
    background-color:#f0f0f5;
    border:1px solid black;
    border-radius:5px;
    align-self:center
}

/*LABELS AND INPUTS*/
