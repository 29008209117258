

.contentWrapper{
    display:flex;
    height:90vh;
    background-color:#eaeaea;
}
.contentWrapper>div{
    flex:1;
    height:100%;
    padding:20px;
    display:flex;
    align-items: flex-start;
    justify-content: center;
}
div.imageWrapper{
    align-items: center;
}
.contentWrapper div img{
    height:80%;
}
.text{
    width:70%;
}
.text h2{
    color:var(--general-blue);
    font-family:TitleFont;
    font-size:calc(3 * 1.2vw + 1rem);
    font-weight:lighter;
    text-align:left;
}
@media (max-width: 768px) {
    .contentWrapper{
        flex-direction:column;
        height:auto;
    }
}