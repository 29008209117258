/* HamburgerIcon.module.css */
.hamburgerIcon {
    width:50px;
    height:50px;
    display:none;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
}

.bar {
    width:100%;
    height:3px;
    transform-origin: 0% 100%;
    transition: transform 0.4s ease, width 0.4s ease;
}
.bar1{background-color:#000;}
.bar2{background-color:#000;}
.bar3{background-color:#000;}

/*
    Someone should do some math to figure out the perfect width for this
    aint gonna be me though, I eyeballed this
*/
.barOpen.bar{
    width:93%;
}

/*Turns the 3 bars into an X*/
.barOpen.bar1{
    
  transform: rotate(45deg);
}

.barOpen.bar2{
    transform: scaleY(0);
}

.barOpen.bar3{
  transform: rotate(-45deg);
}

.hamburgerIcon{
}
@media (max-width: 768px) {
    .hamburgerIcon{
        display:flex;
    }
}