
.wrapper{
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    min-height:100%;
    background-color: #f0f0f5;
    padding:100px 0px 100px 0px;
}
.content{
    width:500px;
    min-height:100%;
    border-radius:5px;
    border:1px solid black;
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    width: 400px;
    display:flex;
    flex-direction:column;
    gap:25px;
    padding-top:25px;
    padding-bottom:25px;
}

.buttonsWrapper{
    display:flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    width:100%;
}

.contentWrapper{
    flex:1;
}
.contentWrapper h2 {
    color: var(--nav-background);
    margin-bottom: 20px;
    font-size: 25px;
  }
.deactivated{
background-color:#f0f0f5;
}
  
@media (max-width: 768px) {
    .wrapper{
        padding:0px;
        align-items: flex-start;
    }
    .content{
        width:100%;
    }
}