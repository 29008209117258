.container{
    display:flex;
    margin-top:100px;
    width:100%;
    justify-content: space-around;
}

.staffContainer{
    border:5px solid var(--general-orange);
    border-radius:25px;
    width:200px;
}
.staffImageContainer{
    height:70%;
}
.staffContainer img{
    width:100%;
    height:100%;
    object-fit: cover;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
}
.staffContainer h6{
    text-decoration: underline;
}
.staffContainer p, .staffContainer h6{
    margin:10px;
}

@media (max-width: 768px) {
    .container{
        flex-direction: column;
        align-items: center;
        gap:30px;
    }
}
