.wrapper{
  background-color: #eaeaee;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.contactContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width:1400px;
    width:100%;
    min-height:85vh;
    gap:100px;
  }
  .contactContainer>div{
    flex:1;
  }
  .contactUs {
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
  }
  
  .contactUs h1 {
    color: var(--general-blue);
    text-align:left;
    font-size:4rem;
    font-family:TitleFont;
    font-weight:lighter;
  }
  
  .contactUs p {
    font-size: 16px;
    font-family:TitleFont;
    color: #333;
    margin-bottom: 10px;
  }
  .contactInfo{
    display:flex;
    flex-direction:column;
    gap:0px;
  }
  .contactInfo p{
    margin:0px;
  }
  .contactEmail {
    color: var(--general-orange);
    font-weight: bold;
  }
  
  .contactPhone {
    color: #333;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .inputGroup {
    display: flex;
    gap: 10px;
  }
  
  .inputField {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .inputField label {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .inputField input,
  .inputField textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .inputField textarea {
    height: 80px;
    resize: none;
  }
  
  .sendButton {
    margin-top: 10px;
    padding: 10px;
    border: none;
    background-color: #ff6600;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .sendButton:hover {
    background-color: #e65c00;
  }
  
  .contactImage {
    width: 100%;
    height: 100%; /* Adjust the height to match your layout */
    min-height:80vh;
    background-image: url('/public/homepage/bottle.png'); /* Replace with your actual image path */
    background-size: cover; /* Ensures the image fills the div */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents repetition */
  }
  
@media (max-width: 768px) {
  .contactUs h1{
    font-size:2rem;
  }
  .contactContainer{
    height:auto;
  }
  .contactContainer{
    flex-direction:column;
  }
  .contactImage{
    height:auto;
  }
  .contactUs{
    gap:20px;
  }
}