.wrapper{
    display:flex;
    flex-direction:column;
    align-items: center;
    gap:25px;
}

.discountWrapper{
    display:flex;
    flex-direction:column;
    align-items:center;
    gap:10px;
    width:90%;
}

.discountHeader{
    width:100%;
    display:flex;
    flex-direction:column;
    align-items: center;
    background-color:#f0f0f5;
    gap:10px;
    align-self:center;
    border:1px solid black;
    border-radius:5px;
    padding:10px;
    box-sizing:border-box;
}
.discountHeader h2{
    margin:0px;
}

