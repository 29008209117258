


.navbarSpacer {
    top: 0;
    background-color: white;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    padding: 2vw;
    padding-left:3vw;
    padding-right:3vw;
    pointer-events: auto;
    box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.3);
}
.navbar {
    position: fixed;
    top: 0;
    background-color: white;
    z-index: 20;
    width: 100%;
    box-sizing: border-box;
    padding: 2vw;
    padding-left:3vw;
    padding-right:3vw;
    pointer-events: auto;
    box-shadow: 0px 12px 12px 0px #666;
}
.container {
    flex: 0 1 auto;
    margin: 0 auto;
    max-width:1400px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    z-index:100;
    font-family:NavFont;
}
.logoAndTitle{
    display:flex;
    align-items: center;
    gap:calc((0.7)* 1.2vw);
}
.logo{
    width:calc((0.7)* 1.2vw + 2rem);
}
.title{
    font-size: calc((0.7)* 1.2vw + 1rem);
    color:black;
    text-decoration:none;
}

.logo img {
    height: 55px;
}

.navElements {
}

.navElements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
}

.navElements ul li:not(:last-child) {
    margin-right: 30px;
}

.navElements ul a {
    color: var(--nav-text);
    text-decoration: none;
    font-size:calc(.1 * 1.2vw + 1rem);
}
.bookLink{
    background-color:var(--btn-bg);
    padding:17px;
    padding-left:22px;
    padding-right:22px;
    border-radius:100px;
}
.navElements .bookLink{
    color:white;
}

.navElements ul a.active {
    font-weight: 500;
    text-decoration:underline;
}

.navElements ul a.active::after {
    /* content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c; */
}


.navElements {
    display: block;
}

/* div.hamburgerIcon {
    display: none;
} */

 .mobileMenu {
    position:fixed;
    top:60px;
    transition: right 0.4s ease;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100%;
}

 .mobileMenuContents {
    position: relative;
    background: white;
    z-index: 1;
    padding:20px;
    height:85%;
    display:flex;
    flex-direction:column;
    justify-content: center;
}

 .mobileMenuContents a{
    text-decoration:none;
    color:black;
    display:block;
    padding:5px;
    text-align:center;
    border-radius:100px;
}
 .mobileMenuContents a:hover{
    border:1px solid black;
}

.mobileMenu{
    display:none;
}
.mobileMenu .bookLink{
    color:white;
}

@media (max-width: 768px) {
    .container{
        padding:0;
        justify-content: space-between;
    }
    .navElements{
        display:none;
    }
    .mobileMenu{
        display:block;
        z-index:100;
    }
    .mobileMenu a{
        font-size:xxx-large;
    }
    .navElements {
        display: none;
    }

    div.hamburgerIcon {
        display: flex;
    }
}
