.titleImage {
    height: 60vh;
    background-size: cover;
    background-position-y: bottom;
    display:flex;
    flex-direction:column;
    gap:50px;
    justify-content: center;
    align-items: center;
    width:100%;
  }
  
  .titleOverlay {
    height:100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4); /*# White opaque background */
    /* color: #000; Black text */
    color:white;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:20px;
  }

  .titleOverlay h2 {
    font-family:TitleFont;
    margin:0;
    margin-bottom:10px;
    vertical-align:bottom;
    color:white;
    font-weight:lighter;
  }
  .titleOverlay h5{
    font-family:TitleFont;
    font-weight:lighter;
    margin:0;
    vertical-align:bottom;
    color:white;
    font-size:1.5rem;
  }
  .quoteButton{
    margin-top:50px;
  }
  .quotelink{
    background-color:var(--quote-bg);
    padding:15px;
    padding-left:40px;
    padding-right:40px;
    border-radius:100px;
    margin-top:50px;
    color:white;
    text-decoration:none;
    font-size:12px;
  }
  @media (max-width: 768px) {
    
    .titleImage{
      height:100vh;
      background-position-x:35%;
    }
    .titleOverlay h2{
      font-size:calc(3* calc(.012* min(100vh, 900px)) + 1rem);
    }
    .titleOverlay h5{
      font-size:12px;
    }
  }