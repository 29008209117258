body, html, #root{
    height:100%;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}


@font-face {
    font-family: 'NavFont'; /* Choose a name for your font */
    src: url('fonts/Roboto-VariableFont_wdth,wght.ttf') format('woff2'); /* Change the file path and format as needed */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'TitleFont'; /* Choose a name for your font */
    src: url('fonts/Roboto-VariableFont_wdth,wght.ttf') format('woff2'); /* Change the file path and format as needed */
    font-weight: normal;
    font-style: normal;
  }

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

h1 {
    font-size: 6rem;
    text-align: center;
    font-family:TitleFont;
}
h2 {
    font-size: 4rem;
    text-align: center;
    font-family:TitleFont;
}
h3 {
    font-size: 3rem;
    text-align: center;
    font-family:TitleFont;
}

h4 {
    font-size: 2rem;
    text-align: center;
    font-family:TitleFont;
}
h5 {
    font-size: 1rem;
    text-align: center;
    font-family:TitleFont;
}

@media (max-width: 768px) {

    h1 {
        font-size: 4rem;
        text-align: center;
    }
    h2 {
        font-size: 3rem;
        text-align: center;
    }
    h3 {
        font-size: 2rem;
        text-align: center;
    }
    
    h4 {
        font-size: 1rem;
        text-align: center;
    }
    h5 {
        font-size: 0.5rem;
        text-align: center;
    }
}
.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

:root {
    --nav-background: #5A8CA6;
    --nav-text: #000;
    --btn-bg:#1470af;
    --quote-bg:#ff7f00;

    --general-blue:#1470af;
    --general-orange:#ff7f00;

    --head-main: #30799B;
    --head-soft: #ff7f00;
    --head-highlight: #fc5000;
    --unlit-text: #666;

    --div-highlight:#83A9D9;
}

.head-text {
    color: var(--head-main);
}

.highlight-text {
    color: var(--head-highlight);
}

.circleButton{
    color:black;
    border-radius:100px;
    background-color: var(--general-blue);
    width:35px;
    height:35px;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:35px;
}
.circleButton img{
    width:65%;
}

.gradientButton{
    color:black;
    border-radius:100px;
    background:linear-gradient(to right, #F37C39, #FFAA00);
    width:35px;
    height:35px;
    display:flex;
    justify-content: center;
    align-items: end;
    font-size:35px;
}
.gradientText{
    position: relative;
    display: inline-block;
    color: transparent;
    background: linear-gradient(to right, #F37C39, #FFAA00);
    background-clip: text;
    -webkit-background-clip: text;
}
.gradientUnderline{
    position: relative;
    display: inline-block;
    color: black;
    background: linear-gradient(to right, #F37C39, #FFAA00);
    background-clip: text;
    -webkit-background-clip: text;
    text-decoration-color: transparent;
}

.gradientUnderline::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 7px; /* Adjust this value to change the thickness of the underline */
    background: linear-gradient(to right, #F37C39, #FFAA00);
    bottom:0;
}
.roundedBlankButton{
    border:1px solid black;
    border-radius:100px;
    font-size:35px;
    text-decoration:none;
    color:black;
    padding:10px;
}

button {
  background-color: var(--general-blue);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  width:100%;
  border: 1px solid black;
}
button.disabled{
    background-color:#f0f0f5;
    color:var(--general-blue);
}

.quotelink{
    font-family:TitleFont;
    font-size:14px;
    background-color:var(--quote-bg);
    padding:22px;
    padding-left:60px;
    padding-right:60px;
    border-radius:100px;
    margin-top:50px;
    margin-bottom:50px;
    color:white;
    text-decoration:none;
  }

  [data-tooltip] {
    position: relative;
    cursor: pointer;
  }
  
  [data-tooltip]:hover::after,
  [data-tooltip]:focus::after {
    content: attr(data-tooltip);
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    max-width: 80vw; /* Adjust as needed */
    width:300px;
    word-wrap: break-word;
    white-space: normal; /* Ensures wrapping */
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    z-index: 100;
    text-align: center;
  }
  
  /* Ensures the tooltip stays within the viewport */
  [data-tooltip]:hover::after {
    white-space: normal;
    max-width: 80vw; /* Prevents it from exceeding screen width */
    word-wrap: break-word;
  }
  button.slick-prev{
    left:-10px;
  }
  button.slick-next{
    right:-10px;
  }

  
.labelAndInput{
    width:100%;
}
.labelAndInput input, .labelAndInput select{
    border:1px solid black;
    padding:10px;
    width:100%;
    border-radius:5px;
    box-sizing:border-box;
}

.striken{
    text-decoration: line-through;
    color:red;
}