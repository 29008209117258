.wrapper{
    min-height:70vh;
    background-color:hsla(204.39,79.49%,38.24%,1);
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content:center
}
.content>div{
    flex:1;
}
.content{
    display:flex;
}

.wrapper h2, .wrapper li{
    color:white;
    font-family:TitleFont;
}
.wrapper h2{
    padding-top:50px;
    padding-bottom:50px;
    font-size:4rem;
    font-weight:lighter;
}
.careWrapper p{
    font-size:2rem;
}
.careContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.careWrapper {
    position: relative;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    overflow: hidden; /* Ensures the video doesn’t overflow the container */
  }
  
  .backgroundVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
  }

.careWrapper h2{
    font-size:4rem;
    font-weight:lighter;
}
.img{
    display:flex;
    justify-content: center;
    align-items: center;
}
.img img{
    width:80%;
    border-radius:15px;
}

@media (max-width: 768px) {
    .wrapper{
        height:auto;
        padding-bottom:30px;
    }
    .wrapper h2{
        font-size:calc(3* calc(.012* min(100vh, 900px)) + 1rem);
    }
    .content{
        flex-direction:column;
    }
    .careWrapper{
        height:auto;
        padding-bottom:30px;
    }
    .careWrapper h2{
        font-size:calc(3* calc(.012* min(100vh, 900px)) + 1rem);
    }
    .img img{
        margin-top:50px;
    }
}